body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to right, #f93438, #ea585d 25%, #daa9e7 75%, #a084ca); /* Stronger red and lilac gradient */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100vw; /* Full width */
  z-index: 2; /* Ensures the content is above the particles */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the container vertically and horizontally */
}

.countdown-section {
  font-family: "Balsamiq Sans", sans-serif;
  width: 90%;
  max-width: 540px;
  display: flex;
  justify-content: center;
}

.countdown-timer {
  font-size: 1.5em;
  line-height: 1.4;
}

.content-box {
  background: rgba(0, 0, 0, 0.7); /* Darker background for better readability */
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); /* Strong shadow for depth */
  color: white;
  width: 90%; /* Use 90% of the screen width */
  max-width: 600px; /* Restrict maximum width for larger screens */
  min-height: 300px; /* Ensures a consistent height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
}

.sentence {
  font-size: 24px; /* Larger font for readability */
  font-weight: bold;
  color: #e6e6fa; /* Soft lilac text color */
  padding: 20px 0;
  line-height: 1.4;
  font-family: "Balsamiq Sans", sans-serif;
}

.timer {
  font-size: 28px; /* Large timer font for all devices */
  color: #78a2cc; /* Soft yellow color for the timer */
  font-weight: bold;
  letter-spacing: 2px;
}

.start-button {
  font-family: "Balsamiq Sans", sans-serif;
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 18px;
  color: #fff;
  background-color: #b39ddb; /* Lilac button color */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.start-button:hover {
  background-color: #9575cd; /* Darker lilac on hover */
  transform: translateY(-3px); /* Hover elevation effect */
}

.start-button:active {
  background-color: #7e57c2; /* Even darker lilac when clicked */
  transform: translateY(0); /* Resets elevation on click */
}

.image-container {
  width: 16rem;
  height: 16rem;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.countdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Media Queries for responsiveness */

@media (max-width: 768px) {
  .content-box {
    width: 95%; /* Expand to 95% on tablets */
  }

  .sentence {
    font-size: 22px; /* Adjust sentence font size for smaller devices */
  }

  .timer {
    font-size: 24px; /* Slightly smaller timer font for tablets */
  }

  .start-button {
    font-size: 18px; /* Adjust button font size for smaller devices */
  }
}

.flip-clock {
  transform: scale(0.8);
}

@media (max-width: 768px) {
  .countdown-section {
    width: 95%; /* Take up less space on tablets */
    padding: 15px 25px;
  }

  .countdown-timer {
    font-size: 1.2em;
  }

  .flip-clock {
    transform: scale(0.6);
  }
}

@media (max-width: 480px) {
  body {
    overflow-x: hidden; /* Ensure no horizontal scrolling */
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Full width of viewport */
    height: 100vh; /* Full height of viewport */
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  .countdown-section {
    width: 90%; /* Reduced width to fit mobile view */
    padding: 15px 20px; /* Adjust padding */
    text-align: center; /* Ensure countdown is centered */
  }

  .countdown-timer {
    font-size: 1.1em; /* Slightly smaller text size for mobile */
  }

  .content-box {
    width: 90%; /* Use 90% of the screen width to avoid overflow */
    max-width: 480px; /* Ensure the content doesn't exceed the mobile width */
    min-height: 250px; /* Adjust minimum height for mobile */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack content vertically */
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box; /* Avoid content overflow due to padding */
    margin: 0 auto; /* Center the content box horizontally */
  }

  .sentence {
    font-size: 20px; /* Smaller sentence font for mobile */
    text-align: center; /* Ensure text is centered */
  }

  .timer {
    font-size: 22px; /* Adjust timer font size for mobile */
    text-align: center; /* Center the timer */
  }

  .start-button {
    padding: 10px 20px; /* Adjust padding for smaller devices */
    font-size: 16px; /* Smaller button text for mobile */
    text-align: center; /* Center the button */
  }

  .image-container {
    width: 14rem; /* Slightly smaller image size for mobile */
    height: 14rem; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Add some space below the image */
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .flip-clock {
    transform: scale(0.6);
    margin: 0 auto; /* Center the flip clock */
  }
}

